<template>
  <div>
    <h2>Admin Users</h2>
    <div class="row">
      <div class="col-6">
        <search-filter
          :showSearch="true"
          v-model="query.search"
        >
        </search-filter>
      </div>
      <div class="col-6 d-flex justify-content-end search-actions">
        <button class="btn btn-primary" @click="addNewUser">
          Add New user
        </button>
      </div>
    </div>
    <results-info
      :count="count"
      :selectedCount="selectedCount"
    />
    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :gridOptions="gridOptions"
      @gridReady="onGridReady"
      @cellClicked="onCellClicked"
      rowSelection="single"
      domLayout="autoHeight"
      style="width: 100%;"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import Admins from '@/services/Api/Admins';
import SearchFilter from '@/views/components/grids/SearchFilter.vue';
import ResultsInfo from '@/views/components/grids/ResultsInfo.vue';
import GridMixin from '@/views/components/grids/GridMixin';
import { gridColumns, gridDefaultColDef } from '@/views/components/grids/users/UsersGrid';

export default {
  name: 'Users',
  components: {
    AgGridVue,
    SearchFilter,
    ResultsInfo,
  },
  mixins: [
    GridMixin,
  ],
  data() {
    return {
      defaultColDef: gridDefaultColDef,
      columnDefs: gridColumns,
      rowData: [],
    };
  },
  methods: {
    postGridReady() {
      this.refresh();
    },
    async refresh() {
      this.refreshing = true;
      try {
        const {
          data: {
            data: { items },
          },
        } = await Admins.get(1, 1000, this.sortBy, this.direction);
        this.rowData = items;
      } finally {
        this.refreshing = false;
      }
    },
    onCellClicked({ data }) {
      this.$router.push(
        {
          name: 'AdminUsersEdit',
          params: {
            id: data.id,
          },
        },
      );
    },
    addNewUser() {
      this.$router.push(
        {
          name: 'AdminUsersCreate',
        },
      );
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/base/_variables.scss";
@import "@/assets/styles/base/_mixins.scss";

</style>
