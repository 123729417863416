export const gridDefaultColDef = {
  filter: 'agSetColumnFilter',
  resizable: true,
  sortable: true,
};

export const gridColumns = [
  {
    headerName: 'First Name',
    field: 'name',
    sort: 'asc',
    minWidth: 200,
  },
  {
    headerName: 'Surname',
    field: 'surname',
    minWidth: 200,
  },
  {
    headerName: 'Email',
    field: 'email',
    flex: 1,
  },
];
