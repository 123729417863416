<template>
  <div>
    <h2>Admin Users</h2>
    <div class="row">
      <div class="col-6">
        <search-filter
          :showSearch="true"
          v-model="query.search"
        >
        </search-filter>
      </div>
      <div class="col-6 d-flex justify-content-end search-actions">
        <button class="btn btn-secondary" @click="deleteSelected">
          Delete Selected
        </button>
        <button class="btn btn-primary" @click="addNewUser">
          Add New user
        </button>
        <b-dropdown no-caret right variant="grey" toggle-class="py-1 px-2">
          <template #button-content>
            Actions
            <font-awesome-icon class="ml-1" icon="fa-light fa-chevron-down" size="xs" />
          </template>
          <b-dropdown-item>
            <span class="icon">
              <font-awesome-icon :icon="['fal', 'upload']" fixed-width />
            </span>
            Upload
          </b-dropdown-item>
          <b-dropdown-item @click="deleteSelected">
            <span class="icon">
              <font-awesome-icon :icon="['far', 'times']" fixed-width />
            </span>
            Delete Selected
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <results-info
      :count="count"
      :selectedCount="selectedCount"
    />
    <ag-grid-vue
      class="ag-theme-alpine"
      :columnDefs="columnDefs"
      :defaultColDef="defaultColDef"
      :rowData="rowData"
      :gridOptions="gridOptions"
      @gridReady="onGridReady"
      @rowSelected="updateRowSelected"
      :rowSelection="rowSelection"
      domLayout="autoHeight"
      style="width: 100%;"
    >
    </ag-grid-vue>
  </div>
</template>

<script>
import { AgGridVue } from 'ag-grid-vue';
import Admins from '@/services/Api/Admins';
import SearchFilter from '@/views/components/grids/SearchFilter.vue';
import ResultsInfo from '@/views/components/grids/ResultsInfo.vue';
import GridMixin from '@/views/components/grids/GridMixin';
import { gridColumns, gridDefaultColDef } from '@/views/components/grids/demo/DemoGrid';

export default {
  components: {
    AgGridVue,
    SearchFilter,
    ResultsInfo,
  },
  mixins: [
    GridMixin,
  ],
  data() {
    return {
      defaultColDef: gridDefaultColDef,
      columnDefs: gridColumns,
      rowData: [],
    };
  },
  methods: {
    postGridReady() {
      this.refresh();
    },
    async refresh() {
      this.refreshing = true;
      try {
        const {
          data: {
            data: { items },
          },
        } = await Admins.get(1, 1000, this.sortBy, this.direction);
        this.rowData = items;
      } finally {
        this.refreshing = false;
      }
    },
    rowSelected(row) {
      this.selectedRow = row;
    },
    addNewUser() {
      console.log('Add new user');
    },
    deleteSelected() {
      console.log('deleteSelected');
      const selected = this.getSelectedNodes();
      selected.forEach((item) => {
        console.log(item.data.id);
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/base/_variables.scss";
@import "@/assets/styles/base/_mixins.scss";

.button-group-wrapper {
  .btn {
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
</style>
