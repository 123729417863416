<template>
  <b-overlay :show="busy">
    <b-container class="data-entry">
      <h2>Create new user</h2>
      <div class="card">
        <div class="card-body">
          <user-form
            @save="createUser"></user-form>
        </div>
      </div>
    </b-container>
  </b-overlay>
</template>

<script>
import Admins from '@/services/Api/Admins';
import UserForm from '@/views/areas/users/partials/UserForm.vue';

export default {
  components: {
    UserForm,
  },

  data() {
    return {
      busy: false,
    };
  },

  methods: {
    async createUser(form) {
      this.busy = true;
      try {
        const payload = {
          name: form.name,
          surname: form.surname,
          email: form.email,
        };

        await Admins.create(payload);
        this.$snack.success({
          text: 'User created!',
        });

        this.$router.push(
          {
            name: 'AdminUsers',
          },
        );
      } catch (error) {
        this.$snack.success({
          text: 'Problem creating this user, try again please.',
        });
      }
      this.busy = false;
    },
  },
};
</script>

<style lang='scss'>
</style>
