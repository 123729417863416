<template>
    <validation-observer v-slot="{ invalid }">
      <div class="row input-row">
        <div class="col-md-6">
          <ifac-valid-input
            v-model="form.name"
            name="name"
            title="First Name"
            rules="required|max:255"
            required
          ></ifac-valid-input>
        </div>
        <div class="col-md-6">
          <ifac-valid-input
            v-model="form.surname"
            name="surname"
            title="Last Name"
            rules="required|max:255"
            required
          ></ifac-valid-input>
        </div>
      </div>
      <div class="row input-row">
        <div class="col">
          <label v-if="form.id">Email</label>
          <input
            v-if="form.id"
            v-model="form.email"
            disabled
            class="form-control flex-grow-1"
          />
          <ifac-valid-input
            v-if="!form.id"
            v-model="form.email"
            name="email"
            title="Email"
            rules="required|email|max:255"
            required
          ></ifac-valid-input>
        </div>
      </div>
      <b-row class="submit-buttons">
        <b-col cols="2">
          <button type="reset" class="btn btn-light" @click="cancel">
            Cancel
          </button>
        </b-col>
        <b-col cols="10" class="text-right right-col">
          <button
            v-if="form.id"
            type="submit"
            class="btn btn-secondary"
            @click="$emit('delete')"
          >
            Delete
          </button>
          <button
            type="submit"
            :disabled="invalid"
            class="btn btn-primary"
            @click="$emit('save', form)"
          >
            Save
          </button>
        </b-col>
      </b-row>
    </validation-observer>
</template>
<script>
import { IfacValidInput } from '@ifac/ui';

export default {
  components: { IfacValidInput },
  props: {
    user: {
      type: Object,
      default() {
        return {
          id: null,
          name: null,
          surname: null,
          email: null,
        };
      },
    },
  },
  data() {
    return {
      form: { ...this.user },
    };
  },
  methods: {
    cancel() {
      this.$router.push({
        name: 'AdminUsers',
      });
    },
  },
};
</script>
