<template>
  <b-overlay :show="busy">
    <b-container class="data-entry">
      <h2>Edit user</h2>
      <div class="card">
        <div class="card-body">
          <user-form
            v-if="user.id"
            :user="user"
            @delete="deleteUser"
            @save="updateUser"
          ></user-form>
        </div>
      </div>
    </b-container>
  </b-overlay>
</template>

<script>
import Admins from '@/services/Api/Admins';
import UserForm from '@/views/areas/users/partials/UserForm.vue';

export default {
  components: {
    UserForm,
  },

  data() {
    return {
      busy: false,
      user: {},
    };
  },
  methods: {
    async fetchUser() {
      this.busy = true;
      const result = await Admins.show(this.$route.params.id);
      this.user = result.data.data;
      this.busy = false;
    },
    async deleteUser() {
      this.busy = true;
      try {
        await Admins.delete(this.user.id);
        this.$snack.success({
          text: 'User deleted!',
        });

        this.$router.push(
          {
            name: 'AdminUsers',
          },
        );
      } catch (error) {
        this.$snack.success({
          text: 'Problem deleting this user, try again please.',
        });
      }
      this.busy = false;
    },
    async updateUser(form) {
      this.busy = true;
      try {
        const payload = {
          id: form.id,
          name: form.name,
          surname: form.surname,
          email: this.user.email,
        };

        await Admins.update(payload);
        this.$snack.success({
          text: 'User updated!',
        });

        this.$router.push(
          {
            name: 'AdminUsers',
          },
        );
      } catch (error) {
        this.$snack.success({
          text: 'Problem updating this user, try again please.',
        });
      }
      this.busy = false;
    },
  },
  created() {
    this.fetchUser();
  },
};
</script>

<style lang='scss'>
</style>
